import { BankQuestionsPage } from 'app/pages/bank-questions/bank-questions/Loadable'
import { ListBannedUser } from 'app/pages/banned-user/list-banned-user/Loadable'
import { AddBlogPage } from 'app/pages/blog/add-blog-page/Loadable'
import { UpdateCategoryPage } from 'app/pages/category/edit-category-page'
import { ListCategoryPage } from 'app/pages/category/list-category-page'
import { ListCommentPage } from 'app/pages/comment/list-comment-page/loadable'
import { AddCourseCategoryPage } from 'app/pages/course/course-category/add-course-category-page'
import { UpdateCourseCategoryPage } from 'app/pages/course/course-category/edit-course-category-page'
import { ListCourseCategoryPage } from 'app/pages/course/course-category/list-course-category-page'
import { AddCoursePage } from 'app/pages/course/course/add-course-page/loadable'
import { EditCoursePage } from 'app/pages/course/course/edit-course-page/loadable'
import { ListCoursePage } from 'app/pages/course/course/list-course-page'
import { AddModulePage } from 'app/pages/course/module/add-module-page/Loadable'
import { EditModulePage } from 'app/pages/course/module/edit-module-page/Loadable'
import { ListModulePage } from 'app/pages/course/module/list-module-page/Loadable'
import { AddSubModulePage } from 'app/pages/course/module/submodule/add-sub-module-page'
import { EditSubModulePage } from 'app/pages/course/module/submodule/edit-sub-module-page/Loadable'
import { ListSubmoduleDetailPage } from 'app/pages/course/module/submodule/list-sub-module-page'
import { DashboardPage } from 'app/pages/dashboard-page/Loadable'
import { ListCandidateListenersPage } from 'app/pages/listener/list-candidate-listener-page'
import { AddPostPage } from 'app/pages/post/add-post-page/Loadable'
import { EditPostPage } from 'app/pages/post/edit-post-page/Loadable'
import { ListPostPage } from 'app/pages/post/list-post-page/Loadable'
import { ListReportTicketPage } from 'app/pages/report-ticket/list-report-ticket/Loadable'
import { AddRolePage } from 'app/pages/role/add-role-page/Loadable'
import { EditRolePage } from 'app/pages/role/edit-role-page/Loadable'
import { ListRolePage } from 'app/pages/role/list-role-page/Loadable'
import { AddRoomInvitationPage } from 'app/pages/room-invitation/add-room-invitation/Loadable'
import { EditRoomInvitationPage } from 'app/pages/room-invitation/edit-room-invitation/Loadable'
import { ListRoomInvitationPage } from 'app/pages/room-invitation/list-room-invitation/Loadable'
import { AddSettingPage } from 'app/pages/setting/add-setting-page/Loadable'
import { EditSettingPage } from 'app/pages/setting/edit-setting-page/Loadable'
import { IndexSettingPage } from 'app/pages/setting/index-setting-page/Loadable'
import { ListSchedulePage } from 'app/pages/setting/list-schedule-page/Loadable'
import { ListSettingPage } from 'app/pages/setting/list-setting-page/Loadable'
import { NewsSettingPage } from 'app/pages/setting/news-setting-page/Loadable'
import { SeoSettingPage } from 'app/pages/setting/seo-setting-page/Loadable'
import { AddTagPage } from 'app/pages/tag/add-tag-page'
import { ListTagPage } from 'app/pages/tag/list-tag-page'
import { AddUserPage } from 'app/pages/user/add-user-page/Loadable'
import { EditUserPage } from 'app/pages/user/edit-user-page/Loadable'
import { ListUserPage } from 'app/pages/user/list-user-page/Loadable'
import { RoutesType } from 'types/Utils'
import { ROUTE_NAMES } from 'utils/constants'
import { EditBlogPage } from '../app/pages/blog/edit-blog-page/index'
import { ListBlogPage } from '../app/pages/blog/list-blog-page/index'
import { AddCategoryPage } from '../app/pages/category/add-category-page/loadable'
import { EditTagPage } from '../app/pages/tag/edit-tag-page/index'

export const ROUTES: RoutesType[] = [
  { path: '/', exact: true, name: 'Home', component: DashboardPage },

  /** Post route */
  { path: '/post/list', exact: true, name: ROUTE_NAMES.postList, component: ListPostPage },
  { path: '/post/add', exact: true, name: ROUTE_NAMES.postAdd, component: AddPostPage },
  { path: '/post/edit/:id', exact: true, name: ROUTE_NAMES.postEdit, component: EditPostPage },

  /** Role routing */
  { path: '/role/list', exact: true, name: ROUTE_NAMES.roleList, component: ListRolePage },
  { path: '/role/add', exact: true, name: ROUTE_NAMES.roleAdd, component: AddRolePage },
  { path: '/role/edit/:id', exact: true, name: ROUTE_NAMES.roleEdit, component: EditRolePage },

  /** User routing */
  { path: '/user/list', exact: true, name: ROUTE_NAMES.userList, component: ListUserPage },
  { path: '/user/add', exact: true, name: ROUTE_NAMES.userAdd, component: AddUserPage },
  { path: '/user/edit/:id', exact: true, name: ROUTE_NAMES.userEdit, component: EditUserPage },

  /** Setting routing */
  { path: '/setting/list', exact: true, name: ROUTE_NAMES.settingList, component: ListSettingPage },
  { path: '/setting/add', exact: true, name: ROUTE_NAMES.settingAdd, component: AddSettingPage },
  { path: '/setting/edit/:id', exact: true, name: ROUTE_NAMES.settingEdit, component: EditSettingPage },
  { path: '/setting/seo-setting-page', exact: true, name: ROUTE_NAMES.seoSetting, component: SeoSettingPage },
  { path: '/setting/news-setting-page', exact: true, name: ROUTE_NAMES.newsSetting, component: NewsSettingPage },
  { path: '/setting/index-setting-page', exact: true, name: ROUTE_NAMES.trangChuBlockSetting, component: IndexSettingPage },
  { path: '/setting/list-schedule-page', exact: true, name: ROUTE_NAMES.scheduleSettingList, component: ListSchedulePage },

  /** Category routing */
  { path: '/category/list', exact: true, name: ROUTE_NAMES.categoryList, component: ListCategoryPage },
  { path: '/category/add', exact: true, name: ROUTE_NAMES.categoryAdd, component: AddCategoryPage },
  { path: '/category/category-edit/:id', exact: true, name: ROUTE_NAMES.categoryEdit, component: UpdateCategoryPage },

  /** Report ticket routing */
  { path: '/report-ticket/list', exact: true, name: ROUTE_NAMES.reportTicketList, component: ListReportTicketPage },

  /** Tag routing */
  { path: '/tag/list', exact: true, name: ROUTE_NAMES.tagList, component: ListTagPage },
  { path: '/tag/add', exact: true, name: ROUTE_NAMES.tagAdd, component: AddTagPage },
  { path: '/tag/tag-edit/:id', exact: true, name: ROUTE_NAMES.tagEdit, component: EditTagPage },

  /** Blog routing */
  { path: '/blog/add', exact: true, name: ROUTE_NAMES.blogAdd, component: AddBlogPage },
  { path: '/blog/list', exact: true, name: ROUTE_NAMES.blogList, component: ListBlogPage },
  { path: '/blog/blog-edit/:id', exact: true, name: ROUTE_NAMES.blogEdit, component: EditBlogPage },

  /** Listener routing */
  { path: '/listener/candidate/list', exact: true, name: ROUTE_NAMES.candidateListenerList, component: ListCandidateListenersPage },

  /** Listener routing */
  { path: '/bank-question/list', exact: true, name: ROUTE_NAMES.bankQuestionList, component: BankQuestionsPage },
  /** Comment routing */
  { path: '/comment/list', exact: true, name: ROUTE_NAMES.commentList, component: ListCommentPage },

  /** banned user routing */
  { path: '/banned-user/list', exact: true, name: ROUTE_NAMES.bannedUserList, component: ListBannedUser },

  /** course category routing */
  { path: '/course-category/list', exact: true, name: ROUTE_NAMES.courseCategoryList, component: ListCourseCategoryPage },
  { path: '/course-category/add', exact: true, name: ROUTE_NAMES.courseCategoryAdd, component: AddCourseCategoryPage },
  { path: '/course-category-edit/:id', exact: true, name: ROUTE_NAMES.courseCategoryEdit, component: UpdateCourseCategoryPage },

  /** course category routing */
  { path: '/course/list', exact: true, name: ROUTE_NAMES.courseList, component: ListCoursePage },
  { path: '/course/add', exact: true, name: ROUTE_NAMES.courseAdd, component: AddCoursePage },
  { path: '/course-edit/:id', exact: true, name: ROUTE_NAMES.courseEdit, component: EditCoursePage },

  /** course module routing */
  { path: '/course/:courseId/module/list', exact: true, name: ROUTE_NAMES.moduleList, component: ListModulePage },
  { path: '/course/:courseId/module/add', exact: true, name: ROUTE_NAMES.moduleAdd, component: AddModulePage },
  { path: '/course/:courseId/module-edit/:moduleId', exact: true, name: ROUTE_NAMES.moduleEdit, component: EditModulePage },

  /** course submodule routing */
  { path: '/course/:courseId/module/:moduleId/submodule/list', exact: true, name: ROUTE_NAMES.submoduleList, component: ListSubmoduleDetailPage },
  { path: '/course/:courseId/module/:moduleId/submodule/add', exact: true, name: ROUTE_NAMES.submoduleAdd, component: AddSubModulePage },
  { path: '/course/:courseId/module/:moduleId/submodule-edit/:submoduleId', exact: true, name: ROUTE_NAMES.submoduleEdit, component: EditSubModulePage },

  // room invitation
  { path: '/room-invitation/list', exact: true, name: ROUTE_NAMES.roomInvitationList, component: ListRoomInvitationPage },
  { path: '/room-invitation/add', exact: true, name: ROUTE_NAMES.roomInvitationAdd, component: AddRoomInvitationPage },
  { path: '/room-invitation/room-invitation-edit/:id', exact: true, name: ROUTE_NAMES.roomInvitationEdit, component: EditRoomInvitationPage },
]

export const ROUTE_RECORDS = ROUTES.reduce((acc, cur) => {
  if (!acc[cur.name!]) {
    acc[cur.name!] = {
      exact: cur.exact,
      path: cur.path,
      name: cur.name,
    }
  }
  return acc
}, {} as Record<string, Pick<RoutesType, 'exact' | 'path' | 'name'>>)

export const DEFAULT_ROUTE = '/dashboard'
export const PAGE_NOTFOUND = '/page-notfound'
